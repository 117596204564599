body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
:hover::-webkit-scrollbar-thumb {
  display: block;
}
::-webkit-scrollbar-thumb {
  background: #98989c;
  background: #d2d2d2;
  border-radius: 8px;
  display: none;
}

.react-datepicker__week .react-datepicker__day--selected,
.react-datepicker__week .react-datepicker__day--in-selecting-range,
.react-datepicker__week .react-datepicker__day--in-range,
.react-datepicker__week .react-datepicker__month-text--selected,
.react-datepicker__week .react-datepicker__month-text--in-selecting-range,
.react-datepicker__week .react-datepicker__month-text--in-range,
.react-datepicker__week .react-datepicker__quarter-text--selected,
.react-datepicker__week .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__week .react-datepicker__day--selected:hover,
.react-datepicker__week .react-datepicker__day--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__day--in-range:hover,
.react-datepicker__week .react-datepicker__month-text--selected:hover,
.react-datepicker__week .react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__month-text--in-range:hover,
.react-datepicker__week .react-datepicker__quarter-text--selected:hover,
.react-datepicker__week
  .react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__quarter-text--in-range:hover,
.react-datepicker__week .react-datepicker__year-text--selected:hover,
.react-datepicker__week .react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__year-text--in-range:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: #f7b500!important;
}
